<template>
	<div class="container">
		<policy-content :content="content"></policy-content>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import PolicyContent from '@/components/gadget/PolicyContent';

export default {
	components: {
		PolicyContent
	},
	data() {
		return {
			content: this.getContent()
		};
	},
	methods: {
		getContent() {
			const title = 'ข้อมูลเกี่ยวกับ ECO Sticker';
			const content = `
				<p>ระบบป้ายข้อมูลยางรถยนต์ตามมาตรฐานสากล หรือ Eco sticker คือ ป้ายแสดงข้อมูลของยางรถยนต์ตามมาตรฐานสากล และสามารถดูข้อมูลยางได้จากการ Scan QR Code ที่อยู่บนป้าย ECO Sticker</p>

				<p>สำหรับข้อมูลบนป้ายฉลากยางรถยนต์มีข้อมูล สำคัญ 4 รายการ คือ</p>

				<p><strong>ประสิทธิภาพการใช้เชื้อเพลิง (Fuel Efficiency)</strong></p>

				<p>ความต้านทานการหมุน เป็นเรื่อง Performance (มีเรื่องความปลอดภัยรวมด้วย) เวลาเบรกกระทันหัน ไม่ใช่รถหมุน 360 องศา ไร้ทิศทาง ถ้ายางดีมีมาตรฐานอาจหมุนบ้าง แต่จะต้องกลับมาให้ได้ ยางรถยนต์ที่ต้านทานการหมุนน้อย จะใช้เชื้อเพลิงน้อยลง</p>
				
				<p>
					ระดับ A (สีเขียว) ประสิทธิภาพสูงที่สุด
					<br />
					ระดับ G (สีแดง)&nbsp; ประสิทธิภาพน้อยที่สุด
				</p>

				<p><strong>การยึดเกาะบนถนนเปียก (Wet Grip)</strong></p>

				<p>แม้ว่า เวลาถนนเปียก รถอาจมีการสไลด์ แต่ควรสไลด์ไปไม่มาก และเป็นไปในทิศทางที่ปลอดภัย ต้องบังคับเพราะ Performance ช่วยชีวิตคุณได้</p>

				<p>
					ระดับ A เกาะถนนเปียกได้ดีเยี่ยม
					<br />
					ระดับ G เกาะถนนเปียกได้น้อยที่สุด
				</p>

				<p><strong>ระดับเสียงจากการสัมผัสถนน (External Rolling Noise)</strong></p>

				<p>เสียงดังจากการวิ่ง ถ้าสังเกตและฟัง เวลารถวิ่ง และมีเสียงจากภายนออกเข้ามาสู่ภายในห้องโดยสาร เสียงดังมาก อาจจะมาจากยางรถที่ใช้</p>

				<p>สัญลักษณ์คลื่นเสียง แสดง 3 ระดับของเสียงสัมผัสถนน ระดับความดังหน่วยเป็น เดซิเบล (dB)</p>

				<p>
					1 ขีด เบาที่สุด
					<br />
					3 ขีด ดังที่สุด
				</p>

				<p>*ระดับเสียงต่อเนื่องที่สูงกว่า 80 เดซิเบลเป็นอันตรายต่อสุขภาพ</p>

				<p><strong>ประเภทยาง&nbsp; (Tire Class)</strong></p>

				<p>แสดงประภทของยาง (เกณฑ์การให้คะแนนความปลอดภัยจะแบ่งตามประเภทของยาง)</p>

				<p>
					C1 สำหรับ รถเก๋ง รถเอสยูวี (SUV) และ รถกระบะ แบบเรเดียล (radial)
					<br />
					C2 สำหรับ รถกระบะเชิงพาณิชย์ แบบเรเดียล (radial)
					<br />
					C3 สำหรับ รถบรรทุก แบบผ้าใบเฉียง (bias)
				</p>

				<p><strong>ตัวอย่าง Eco Sticker ยางรถยนต์</strong></p>

				<img src="` + require('@/assets/content-eu.jpg') +`" alt="ข้อมูลเกี่ยวกับ ECO Sticker" title="ข้อมูลเกี่ยวกับ ECO Sticker" />
			`;

			return {
				title: title,
				detail: content
			};
		}
	},
	metaInfo() {
		const title = 'ข้อมูลเกี่ยวกับ ECO Sticker | YELLOWTiRE';
		const desc = 'ระบบป้ายข้อมูลยางรถยนต์ตามมาตรฐานสากล หรือ Eco sticker คือ ป้ายแสดงข้อมูลของยางรถยนต์ตามมาตรฐานสากล และสามารถดูข้อมูลยางได้จากการ Scan QR Code ที่อยู่บนป้าย ECO Sticker สำหรับข้อมูลบนป้ายฉลากยางรถยนต์มีข้อมูล';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
    }
}
</script>