<template>
    <section class="content-box">
        <h1>{{ content.title }}</h1>
        <article class="mt-4" v-html="content.detail">
        </article>
    </section>
</template>

<script>
export default {
    props: {
        content: Object
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

::v-deep.content-box {
    margin: 15px 0;
    padding: 20px;
    background: #fff;

    h1 {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
    }

    article {
        font-size: 13px;
        line-height: 18px;

        h2, h3, h4 {
            font-size: 14px;
            font-weight: bold;
            margin: 0;
        }

        p, ul, ol {
            margin: 15px 0 20px;
        }

        ol {
            li {
                display: block;
            }

            & > li {
                counter-increment: item;
                position: relative;

                &:first-child {
                    counter-reset: item;
                }

                &:before {
                    content: counters(item, ".") ". ";
                    position: absolute;
                    margin-right: 100%;
                    right: 10px;
                }
            }
        }

        img {
            display: block;
            max-width: 100%;
            margin: 30px 0;
        }

        table {
            width: 100%;
            margin: 20px 0;
            
            td {
                border: 1px solid #666;
                padding: 5px 8px;
            }
        }
    }
}
</style>